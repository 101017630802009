.bg-color {
    background-color: azure;
}

.bg-color-alarm {
    background-color: #f8d7da;
}

.details-footer {
    height: 35vh;

}.card-body {
    height: 100px;
    border-bottom-left-radius:  5px;
    border-bottom-right-radius:  5px;
}

.card-body span {
    font-weight: bold;
}

.connected {
    color: green;
}

.disconnected {
    color: rgb(255, 187, 0);
}

@media (max-width: 350px) {
    .card-body {
        height: 130px;
    }
}
